
import React from "react";
import {useParams} from "react-router-dom";
import feapiurl from "../feapiurl";
import axios from "axios";
import MasterDivs from "../content/MasterDivs";
import JobDurationContent from "../content-parts/JobDurationContent";
import JobsLocation from "./JobsLocation";
import LoadImageJobs from "../frontenddata/LoadImageJobs";
import LoadPageContent from "../frontenddata/LoadPageContent";
import LoadImageContent from "../frontenddata/LoadImageContent";
import JobSpeciaOffer from "./JobSpeciaOffer";
import JobsAdvantage from "./JobsAdvantage";
import LoadJobsContent from "../frontenddata/LoadJobsContent";
import PageContentLoader from "../pagecontent/PageContentLoader";
import jdtyles from '../styles/css/templates/jobdetail.module.css';
function JobDetailView(){
    const { joburl } = useParams();
    const [jobData, setJobData] =  React.useState([])
    const [hasNoData, setHasNoData] = React.useState( false );
    const [pageContentData, setPageContentData] = React.useState([]);
    const fetchurl = feapiurl + "getjobdatatag.php";
    const areaname = "Jobs";
    const pcurl = feapiurl + "getpagecontentbyarea.php";


    React.useEffect(() => {
        const pcData = async () => {
            try {
                const response = await axios.post(pcurl, { areaname });
                const result = response.data;
                setPageContentData(result);
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        pcData();
    }, [areaname]);
    React.useEffect(()=>{

        document.body.className = "jobddetailview";

    })

    React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, {joburl});
                const result = response.data;
                if(result.length > 0) {


                    setJobData(result[0]);

                }
                else{
                    setHasNoData(true);
                }
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, [joburl]);


    return(



        <section className="jobdetail">
        {

            hasNoData
            ?(
                <MasterDivs hasFlexRow={false}>
                    <h1>Leider konnten wir das Jobangebot nicht mehr finden.</h1>
                    <LoadJobsContent/>
                </MasterDivs>

                )
                :
                (


                    <section className={jdtyles.jdtopcontent}>
                <MasterDivs hasFlexRow={false} textAlign="center"marginBottom="0px" backgroundColor="#F9F3EB" marginhorizontal="0px">


                   <section className={jdtyles.jdinnercontent}>
                       <h5>{jobData.anz_slogan}</h5>
                    <h1>{jobData.anz_slogan_title}</h1>

                       {pageContentData[1] && (
                           <JobDurationContent
                               headline={pageContentData[1].headline}
                               content={pageContentData[1].text}
                           />
                       )}
                   </section>

                </MasterDivs>
            <LoadImageJobs jobdata={jobData} mediaType="slider" marginBottom="0px"  marginhorizontal="0px" className={jdtyles.jodetailimagebox}>
                        <JobsLocation jobData={jobData} backgroundColor="#DBC5A5"/>
            </LoadImageJobs>

                        <LoadPageContent pageid="1" hasFlexRow={true} contentClass="ueberuns" backGroundColor="#004F9F" marginBottom="0px" marginhorizontal="0px" />
                        <LoadImageContent pageid="1" mediaType={"gallery"} marginBottom="0px" className="ueberunsgallery unsjobdetail"/>


        <JobsAdvantage advantages={jobData.anz_vorteile} todos={jobData.anz_aufgaben} advantagetitle={jobData.anz_vorteile_title} todotitle ={jobData.anz_aufgaben_title}/>

                        {pageContentData[0] && (

                        <JobSpeciaOffer marginhorizontal="0px" jobid={jobData.anz_id} routing={jobData.anz_routing}      headline={pageContentData[0].headline} description={pageContentData[0].text}/>

                            )}
        <LoadJobsContent/>
               </section>
                )
        }




            </section>
                )


}
export default JobDetailView;