import imageurl from "../imageurl";
import React, { useState } from "react";
import sliderstyles from '../styles/css/contentparts/imageslide.module.css';

function ImageJobsGallery({ loadedImages }) {


    return (
        <section className={sliderstyles.imagegallery}>
            <ul>
                {
                 loadedImages.map((img) =>(

                     <li key={img.img_id}>
                         <img
                             alt={img.img_path}
                             src={imageurl + "/" + img.img_path}
                         />
                     </li>
                 )

                )}


            </ul>





              </section>
    )
}

export default ImageJobsGallery;
