import React from "react";
import Button from "./Button";
import homestyle from "../styles/css/content/homecontent.module.css";
import ContentHome from "../content-parts/ContentHome";
import LoadPageContent from "../frontenddata/LoadPageContent";
import LoadImageContent from "../frontenddata/LoadImageContent";
import LoadJobsContent from "../frontenddata/LoadJobsContent";
import VideoPlayer from "../content-parts/VideoPlayer";
import feapiurl from "../feapiurl";
import axios from "axios";

function HomeContent(){
const [hasVideo, setHasVideo] = React.useState(false);
const [VideowidhContent, setVideoWithContent] = React.useState(false);

const [VideoContent, setVideoContent] =  React.useState([]);
const [videoPoster, setVideoPoster] = React.useState("");
const [videoHeadline, setVideoHeadline] = React.useState("");
const [videoText, setVideoText] = React.useState("");

React.useEffect(()=>{

   document.body.className = "home";

})


    const fetchurl =feapiurl+ "getvideocontent.php";
const pageid = 4;
    React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, {pageid});
                const result = response.data;

                if(result.length > 0) {

                    setVideoContent(result[0]);

                    setHasVideo(true);
                   const hasContent = result[0].pvi_headline;
                   const videoText = result[0].pvi_content;
if(hasContent !=""){
                setVideoWithContent(true)
                setVideoHeadline(hasContent);
                setVideoText(videoText);
}
else{
    setVideoWithContent(false);
}

                }
                else{
                    setHasVideo(false);
                }
            }

            catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, [VideoContent]);


    return(
    <section className={homestyle.pagehome}>
    <section className={homestyle.homecontent}>
<LoadPageContent pageid="4" hasFlexRow={false} />

    </section>
        <LoadImageContent pageid="4" mediaType="slider" marginBottom="0px"/>

        <LoadJobsContent hasFlexRow={true}/>
        {
            hasVideo ?(<VideoPlayer  videoText= {videoText} videoHeadline = {videoHeadline} showvideascontent = {VideowidhContent} videocontent={VideoContent} postercontent />):("")
        }
        <LoadPageContent pageid="1" hasFlexRow={true} backGroundColor="#004F9F" marginhorizontal="0px" contentClass="ueberuns"/>

        <LoadImageContent pageid="1" mediaType={"gallery"} marginBottom="0px" className={"ueberunsgallery"}/>


    </section>
    )

}
export default HomeContent;
